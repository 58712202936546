<template>
  <div>
    <TopNavVue/>
    <div>
      <el-row class="cloudDesktop">
        <el-col :span="22" :offset="1" class="wga">
          <!-- 页面标题 -->
          <h6 class="title">渠道体系</h6>
          <p>磐石（南京）云计算有限公司致力于信息技术现代服务业和自有品牌发展，业务范围辐射全国。</p>
          <p>磐石重视自身能力打造，积极推动自有品牌建设，推出磐石云桌面、磐石云终端、磐石一体机、磐石视频运维安全管理系统等系列自有品牌产品。现面向全国寻找优秀合作伙伴共同开拓区域市场，欢迎有识之士联系洽谈、共谋合作共赢之道!</p>
          <p>磐石，愿与您共创美好未来！</p>
          <img src="@/assets/qdtx/q1.png"/>
          <!-- <p><span></span>北京：王恭伟 13621145748</p>
          <p><span></span>上海：张雪峰 13390897755</p>
          <p><span></span>陕西：魏鹏林 13669278086</p>
          <p><span></span>广州：李文财 13829280250</p>
          <p><span></span>武汉：宋元冰 13986242776</p>
          <p><span></span>运营中心：张云凤 15694476786</p>
          <p><span></span>售后服务：400-097-6787</p> -->
        </el-col>

      </el-row>
    </div>
    <footerMobileVue/>
  </div>
</template>

<script>
import TopNavVue from '../../components/topNav.vue'
import footerMobileVue from '../../components/footerMobile.vue';
export default {
  name:'渠道体系、Mobile',
  components:{footerMobileVue,TopNavVue},

  
}
</script>

<style scoped>
  .cloudDesktop{background: #f5f5f5;padding-top: 17%;padding-bottom: 20px;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .wga{padding-top: 10px;}
  .stc p span{display: inline-block;width: 8px;height: 8px;border-radius: 4px;background: #888;margin-right: 5px;}
  .wga p{font-size: 3vw;color: #888;width: 100%;margin: 15px auto;text-indent: 2em;}
  .wga img{width: 100%;}
</style>